// src/hooks/useSessionTimeout/useSessionTimeout.ts

import { useState, useEffect, useCallback } from 'react';

export default function useSessionTimeout(inactivityTimeout: number, promptDuration: number) {
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastActivity, setLastActivity] = useState(Date.now());

  //console.log('useSessionTimeout initialized');
  //console.log('Initial lastActivity:', new Date(lastActivity).toISOString());

  const resetTimeout = useCallback(() => {
    //console.log('resetTimeout called');
    setLastActivity(Date.now());
    setIsTimedOut(false);
    setShowPrompt(false);
  }, []);

  useEffect(() => {
    //console.log('Setting up timeout effect');
    const checkInactivity = () => {
      const currentTime = Date.now();
      const inactiveTime = currentTime - lastActivity;
      //console.log('Checking inactivity. Inactive time:', inactiveTime, 'ms');

      if (inactiveTime >= inactivityTimeout + promptDuration) {
        console.log('Logging: Session timed out');
        setIsTimedOut(true);
      } else if (inactiveTime >= inactivityTimeout) {
        console.log('Logging: Showing prompt');
        setShowPrompt(true);
      }
    };

    const intervalId = setInterval(checkInactivity, 1000);

    return () => {
      //console.log('Clearing timeout interval');
      clearInterval(intervalId);
    };
  }, [lastActivity, inactivityTimeout, promptDuration]);

  useEffect(() => {
    //console.log('Setting up activity listeners');
    const handleActivity = () => {
      console.log('Activity detected');
      resetTimeout();
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    return () => {
      //console.log('Removing activity listeners');
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, [resetTimeout]);

  return { isTimedOut, showPrompt, resetTimeout };
}
