// src/hooks/useSessionManagement/useSessionManagement.ts

import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useSessionTimeout from '../useSessionTimeout/useSessionTimeout';
import { useAppState } from '../../state';
import useVideoContext from '../useVideoContext/useVideoContext';
import { isValidUrl } from '../../utils/urls/urls';
import { User } from '../../types';

const DEFAULT_INACTIVITY_TIMEOUT = 30 * 60 * 1000; // default value 30 minutes
const DEFAULT_PROMPT_DURATION = 60 * 1000; //  default value 1 minute

export default function useSessionManagement() {
  const history = useHistory();
  const { signOut, user, redirectionScreenout } = useAppState();
  const { room, localTracks } = useVideoContext();

  const inactivityTimeout = process.env.REACT_APP_INACTIVITY_TIMEOUT
    ? parseInt(process.env.REACT_APP_INACTIVITY_TIMEOUT, 10)
    : DEFAULT_INACTIVITY_TIMEOUT;

  const promptDuration = process.env.REACT_APP_PROMPT_DURATION
    ? parseInt(process.env.REACT_APP_PROMPT_DURATION, 10)
    : DEFAULT_PROMPT_DURATION;

  //console.log('useSessionManagement initialized');
  //console.log('Inactivity Timeout:', inactivityTimeout, 'ms');
  //console.log('Prompt Duration:', promptDuration, 'ms');

  const { isTimedOut, showPrompt, resetTimeout } = useSessionTimeout(inactivityTimeout, promptDuration);

  const [sessionExtended, setSessionExtended] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSignOut = useCallback(() => {
    //console.log('handleSignOut called');
    if (room) {
      room.disconnect();
    }
    localTracks.forEach(track => track.stop());

    if (signOut) {
      const redirectionUrl = (user as User)?.userPreferences?.redirectionSuccess;
      if (redirectionScreenout && isValidUrl(redirectionScreenout)) {
        //console.log('Redirecting to screenout URL:', redirectionScreenout);
        signOut(redirectionScreenout);
      } else if (redirectionUrl && isValidUrl(redirectionUrl)) {
        //console.log('Redirecting to success URL:', redirectionUrl);
        signOut(redirectionUrl);
      } else {
        //console.log('No valid redirection URL, performing default signout');
        signOut();
      }
    }
    history.push('/login');
  }, [signOut, user, history, room, localTracks, redirectionScreenout]);

  useEffect(() => {
    console.log('showPrompt changed:', showPrompt);
    if (showPrompt) {
      //console.log('Session timeout warning triggered:', new Date().toISOString());
      setDialogOpen(true);
      setSessionExtended(false);
    }
  }, [showPrompt]);

  useEffect(() => {
    console.log('isTimedOut changed:', isTimedOut);
    if (isTimedOut && user) {
      //console.log('Session timed out, logging out:', new Date().toISOString());
      handleSignOut();
    }
  }, [isTimedOut, user, handleSignOut]);

  const handleDismissDialog = () => {
    //console.log('handleDismissDialog called');
    resetTimeout();
    setSessionExtended(false);
    setDialogOpen(false);
  };

  const handleExtendSession = () => {
    //console.log('handleExtendSession called');
    setSessionExtended(true);
    resetTimeout();
  };

  return {
    isTimedOut,
    showPrompt,
    sessionExtended,
    dialogOpen,
    handleSignOut,
    handleDismissDialog,
    handleExtendSession,
  };
}
